import React, { useEffect } from 'react';
import SectionHeader from './cmslayout/SectionHeader';
import Overview from './cmslayout/Overview';
import { 
    Container, Breadcrumb, Col, Row, Card, 
    CardHeader, CardBody, Table
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAdmins } from '../../actions/admins';

const Dashboard = ({getAdmins, admins}) => {

    useEffect(() => {
        getAdmins();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <SectionHeader title="Dashboard" buttonLabel="Add Admin" buttonLink="/admin/register" />

            <Container>
                <Breadcrumb>Dashboard</Breadcrumb>
            </Container>

            <Container style={{marginBottom: 20}}>
                <Overview />
            </Container>

            <Container style={{marginBottom: 100}}>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Admin Login History</h2>
                            </CardHeader>

                            <CardBody>                                
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Last Sign in At</th>
                                            <th>Created</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admins.map(admin => {
                                            return (
                                                <tr key={admin._id}>
                                                    <td style={{fontWeight: 'bold'}}>
                                                        {admin.name}
                                                    </td>
                                                    <td>{admin.email}</td>
                                                    <td>{admin.recentLogin}</td>
                                                    <td>{admin.date}</td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

Dashboard.propTypes = {
    getAdmins: PropTypes.func.isRequired,
    admins: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    admins: state.admins.admins
});

export default connect(
    mapStateToProps,
    { getAdmins }
)(Dashboard);