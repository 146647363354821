import { combineReducers } from 'redux';
import announcement from './announcement';
import alert from './alert';
import auth from './auth'
import prayers from './prayers';
import admins from './admins';
import audios from './audios';
import blog from './blog';
import reset from './reset';
import overview from './overview';
import pages from './pages';

export default combineReducers({
    admins,
    alert,
    announcement,
    audios,
    auth,
    blog,
    overview,
    pages,
    prayers,
    reset,
});