import React, { Fragment, Component } from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import HomePage from './home/HomePage';
import About from './pages/About';
import Admin from './cms/Admin';
import Blog from './pages/blog_page/Blog';
import Audios from './pages/audio_page/Audios';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import Resources from './pages/Resources';
import NotFound from './NotFound';
import { BrowserRouter as Router, Route, Switch } from  'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from '../store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '../styles.css';
import AudioView from './pages/audio_page/AudioView';
import BlogView from './pages/blog_page/BlogView';

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      match: null,
      showHeader: true,
      showFooter: true
    }
  }

  hideNavigation = () => {
    this.setState({
      showHeader: false
    })
  }

  hideFooter = () => {
    this.setState({
      showFooter: false
    })
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Fragment>
            {this.state.showHeader && <Header/>}

            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/about" component={About} />

              <Route exact path="/audios" component={Audios} />
              <Route exact path="/audios/:id" component={AudioView} />

              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:id" component={BlogView} />

              <Route exact path="/contact" component={Contact} />
              <Route exact path="/donate" component={Donate} />
              <Route exact path="/resources" component={Resources} />

              <Route 
                path="/admin"
                render={(props) => (
                  <Admin {...props}  hideNavigation={this.hideNavigation} hideFooter={this.hideFooter} />
                )}
              />

              <Route 
                path="*"
                render={(props) => (
                  <NotFound {...props} hideNavigation={this.hideNavigation} hideFooter={this.hideFooter} />
                )}
              />

            </Switch>

            {this.state.showFooter && <Footer/>}
          </Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
