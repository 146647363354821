import React, { useState } from 'react';
import Notify from '../Notify';
import SectionHeader from './cmslayout/SectionHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import { 
    Container, Breadcrumb, Card, Col,
    Row, CardHeader, CardBody, Input, Label
} from 'reactstrap';

const Register = ({register}) => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const { name, email, password, confirmPassword } = formData;

    const onChange = e => 
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        register(name, email, password, confirmPassword);
    }

    return(
        <div>
            <SectionHeader title="Add Admin" buttonLabel="Back" buttonLink="/admin/dashboard" />

            <Container>
                <Breadcrumb>Add Admin</Breadcrumb>
            </Container>

            <Container>
                <Row>
                    <Col lg="3">

                    </Col>
                    <Col lg="9">
                        <Card style={{marginBottom: 50}}>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Register</h2>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={e => onSubmit(e)}>
                                    <Label for="name">Full Name</Label>
                                    <Input
                                        type="text" className="registerInput" value={name} name="name"
                                        placeholder="Enter Full Name" onChange={e => onChange(e)} /><br/>
                                    
                                    
                                    <Label for="email">Enter Email</Label>
                                    <Input
                                        type="email" className="registerInput" value={email} name="email"
                                        placeholder="Enter Email" onChange={e => onChange(e)} /><br/>

                                        
                                    <Label for="password">Enter Password</Label>
                                    <Input
                                        type="password" className="registerInput" value={password} name="password"
                                        placeholder="Enter Password" onChange={e => onChange(e)} /><br/>

                                        
                                    <Label for="confirmPassword">Confirm Password</Label>
                                    <Input
                                        type="password" className="registerInput" value={confirmPassword} 
                                        name="confirmPassword" placeholder="Confirm Password" onChange={e => onChange(e)}/>
                                    
                                    <input type="submit" className="createBtn" value="Create Admin" />
                                    
                                </form>

                                <Notify />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}


Register.propTypes = {
    register: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(
    mapStateToProps,
    { register, setAlert }
)(Register);