import React, { useEffect } from 'react';
import Twitter from '../layout/Twitter';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPage } from '../../actions/pages';

const Donate = ({ getPage, page, history }) => {

    useEffect(() => {
        getPage('donate', history, '/');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Container style={{marginTop: 50, marginBottom: 150}}>
            <Row>
                <Col lg="8">
                    <div dangerouslySetInnerHTML={{ __html: page.pagebody}} />
                </Col>
                <Col lg="4">
                    <div style={{marginLeft: 10, marginRight: 10}}>
                        <h3> Follow Us On Twiiter </h3>
                        <Twitter/>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

Donate.propTypes = {
    getPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    page: state.pages.page
});

export default connect(
    mapStateToProps,
    { getPage }
)(Donate);