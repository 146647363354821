import React, { useEffect } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import { Container, Col, Row, Breadcrumb, Table, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Notify from '../../Notify';
import { setAlert } from '../../../actions/alert';
import { getPrayers, deletePrayer } from '../../../actions/prayers';

const Prayers = ({ getPrayers, deletePrayer, prayers, match }) =>{

    useEffect(() => {
        getPrayers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const removePrayer = (e, id) =>{
        e.preventDefault();
        if (window.confirm('Are you sure you wish to delete this item?')) 
            deletePrayer(id);
    }

    return(
        <div>
            <SectionHeader title="Prayers" buttonLabel="Add Prayer" buttonLink="/admin/prayers/add" />

            <Container>
                <Breadcrumb>Prayers</Breadcrumb>
            </Container>

            <Container style={{marginBottom: 100}}>
                <Row>
                    <Col lg="12">
                    
                        <Notify />

                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Prayers</h2>
                            </CardHeader>
                            <CardBody>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Time</th>
                                            <th>Update</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {prayers.map(prayer => {
                                            return (
                                                <tr key={prayer._id}>
                                                    <td>{prayer.name}</td>
                                                    <td>{prayer.time}</td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: `${match.url}/${prayer._id}`
                                                        }} className="editLink">
                                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <button onClick={e => removePrayer(e, prayer._id)} className="trashBtn">
                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
};

Prayers.propTypes = {
    setAlert: PropTypes.func.isRequired,
    getPrayers: PropTypes.func.isRequired,
    deletePrayer: PropTypes.func.isRequired,
    prayers: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    prayers: state.prayers.prayers
});

export default connect(
    mapStateToProps,
    { setAlert, getPrayers, deletePrayer }
)(Prayers);