import {
    GET_OVERVIEW,
    OVERVIEW_ERROR
} from '../actions/types';

const initialState = {
    overview: [],
    loading: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_OVERVIEW:
            return {
                ...state,
                overview: payload,
                loading: false
            }
        case OVERVIEW_ERROR:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        default:
            return state;
    }
}