import React from 'react';
import { Card, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';

function AudioCard(props) {

    const thumbImg = props.thumbnail === 'default.png' ? require('../../assets/uploads/default.png') : props.audio.thumbnail;

    return(
        <Card className="audioCard" >
            <div style={{paddingLeft: 10, paddingRight: 10}}>
                <CardImg className="audioCardImage" src={thumbImg}  alt="thumbnail" />
            </div>
            
            <div className="audioCardTitle">
                <h3 style={{marginBottom: 0}}>{props.audio.title}</h3>
            </div>

            <hr style={{marginTop: '.25rem', marginBottom: '.5rem', marginLeft: 20, marginRight: 20}}/>

            <p className="audioCardSum">
                {props.audio.summary}
            </p>

            
            <div className="audioCardBtnContainer">
                <Link to={`/audios/${props.audio._id}`} className="audioCardLink" >View Audio</Link>
            </div>

            <hr style={{marginTop: '.25rem', marginBottom: '.5rem'}}/>
            <div className="audioInfo">
                <span>
                    <i className="fa fa-microphone" aria-hidden="true"></i> &nbsp;
                    {props.audio.speaker}
                </span><br/>
                <span>
                    <i className="fa fa-clock-o" aria-hidden="true"></i> &nbsp;
                    {props.audio.date}
                </span>
            </div>

        </Card>
    );
}


export default AudioCard;
