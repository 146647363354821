import React, { useEffect } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Row, Col, Card, CardBody,
    Breadcrumb, CardHeader, Table
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getAudios, deleteAudio } from '../../../actions/audios';

const CmsAudios = ({ getAudios, deleteAudio, audios, match }) =>{

    useEffect(()=>{
        getAudios(1000,'','');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const removeAudio = (e, id) =>{
        e.preventDefault();
        if (window.confirm('Are you sure you wish to delete this item?'))
            deleteAudio(id);
    }

    const topics = [...new Set(audios.map(x => x.topic))];

    return(
        <div>
            <SectionHeader title="Audios" buttonLabel="Add Audio" buttonLink="/admin/audios/add" />

            <Container>
                <Breadcrumb>Audios</Breadcrumb>
            </Container>
            
            <Container style={{marginBottom: 100}}>
                <Row>
                    <Col lg="3">

                    </Col>
                    <Col lg="9">
                        <Notify />
                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Audios</h2>
                            </CardHeader>
                            <CardBody>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Audio Title</th>
                                            <th>Speaker</th>
                                            <th>Topic</th>
                                            <th>Summary</th>
                                            <th>Date Posted</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {audios.map(audio => {
                                            return (
                                                <tr key={audio._id}>
                                                    <td>{audio.title}</td>
                                                    <td>{audio.speaker}</td>
                                                    <td>{audio.topic}</td>
                                                    <td>{audio.summary}</td>
                                                    <td>{audio.date}</td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: `${match.url}/${audio._id}`,
                                                            state: { topics: topics }
                                                        }} className="editLink">
                                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <button onClick={e => removeAudio(e, audio._id)} className="trashBtn">
                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


CmsAudios.propTypes = {
    getAudios: PropTypes.func.isRequired,
    deleteAudio: PropTypes.func.isRequired,
    audios: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    audios: state.audios.audios
});


export default connect(
    mapStateToProps,
    { getAudios, deleteAudio }
)(CmsAudios);