import Axios from 'axios';
import { setAlert } from './alert';
import {
    GET_ADMINS,
    ADMINS_ERROR,
    FORGOT_SUCCESS,
    FORGOT_FAIL
} from './types';

export const getAdmins = () => async dispatch => {
    try {
        const res = await Axios.get('/api/admins');

        dispatch({
            type: GET_ADMINS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: ADMINS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const sendResetLink = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ email });

    try{
        const res = await Axios.post('/api/forgot', body, config);

        dispatch({
            type: FORGOT_SUCCESS,
            payload: res.data
        });

        dispatch(setAlert('Email sent for verification', 'success'));

    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: FORGOT_FAIL
        });
    }
}