import React from 'react';
import PrayerTimings from '../PrayerTimings';
import { Container, Col, Row } from 'reactstrap';

function Headline(){
    return(
        <div className="headline">
            <div className="headline-background"></div>
            <div className="headline-overlay"></div> 
            <Container className="headline-content">
                <Row style={{height: "100%"}}>
                    <Col lg="8" className="my-auto headline-news" style={{textAlign: "left"}}>
                       
                    </Col>
                    <Col lg="4" className="my-auto">
                        <PrayerTimings />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Headline;