import React, { useEffect } from 'react';
import {
    Card, CardBody, Col, 
    Row, CardHeader
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getOverview } from '../../../actions/overview';

const Overview = ({ getOverview, overview }) =>{

    useEffect(() => {
        getOverview();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Card>
            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                <h2 style={{color: '#fff'}}>Website Overview</h2>
            </CardHeader>
            
            <CardBody> 
                <Row>
                    {overview.map(overview => {
                        return(
                            <Col lg="3" key={overview.id}>
                                <Card className="overviewCard">
                                    <h2>{overview.count}</h2>
                                    <h3>{overview.name.charAt(0).toUpperCase() + overview.name.slice(1)}</h3>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </CardBody>
        </Card>
    );
}

Overview.propTypes = {
    getOverview: PropTypes.func.isRequired,
    overview: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    overview: state.overview.overview
});

export default connect(
    mapStateToProps,
    { getOverview }
)(Overview);