import React, { useEffect } from 'react';
import Divider from '../layout/Divider';
import AudioCard from './AudioCard';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAudios } from '../../actions/audios';

const AudioSection = ({ getAudios, audios }) => {

    
    useEffect(() => {
        getAudios(3, '', '');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className="audioSection">
            <Container>
                <div className="title-lines">
                    <h1 style={{fontSize: 36}}>
                        Lastest Audios
                    </h1>
                </div>
            </Container>
                
            <Container style={{marginBottom: 50}}>
                <Row>
                    {audios.map(audio => {
                        return(
                            <Col lg="4" md="6" key={audio._id}>
                                <AudioCard audio={audio} />
                            </Col>
                        )
                    })}
                </Row>
            </Container>
            
            
            <Divider d="M 0 75 L 0 0 L 1005 0 Z" bgcolor="transparent" fill="#ffffff" />
        </div>
    );
}


AudioSection.propTypes = {
    getAudios: PropTypes.func.isRequired,
    audios: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    audios: state.audios.audios
});

export default connect(
    mapStateToProps, 
    { getAudios }
)(AudioSection);