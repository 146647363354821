import React, { useEffect } from 'react';

const Twitter = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    }, []);

    return(
        <div className="twitter-embed">
            <a 
                href="https://twitter.com/MasjidSunnahChi?ref_src=twsrc%5Etfw"
                className="twitter-timeline"
                data-height="900" 
                data-theme="dark" 
                data-link-color="#F5F8FA" >
            </a>
        </div>
    );
};

export default Twitter;