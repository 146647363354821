import React, { Component } from 'react';
import AudioItem from './AudioItem';
import { Col, Row, Container } from 'reactstrap';

class AudioFilter extends Component {

    render(){
        return(
            <Container>
                <Row>
                    {this.props.audios
                        .filter(
                            ({ speaker, topic }) => {
                                if(this.props.speaker !== '' && this.props.topic !== ''){
                                    return speaker === this.props.speaker && topic === this.props.topic;
                                } else if (this.props.speaker !== '' && this.props.topic === ''){
                                    return speaker === this.props.speaker;
                                } else if (this.props.speaker === '' && this.props.topic !== ''){
                                    return topic === this.props.topic;
                                }
                                return 'all';
                            }
                        )
                        .map(
                            audio => {
                                return(
                                    <Col xs="12" sm="6" md="4" key={audio._id} className="d-flex justify-content-center">
                                        <AudioItem audio={audio} />
                                    </Col>
                                );
                            }
                        )
                    }
                </Row>
            </Container>
        );
    }
}

export default AudioFilter;