import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrayers, getTimetable } from '../actions/prayers';

const PrayerTimings = ({ getTimetable, getPrayers, prayers, timetable, match }) => {
    
    useEffect(() => {
        getPrayers();
        getTimetable();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Card className="shadow-sm rounded prayerTimings" style={{marginLeft: 'auto', marginRight: 'auto'}} >
            <CardBody>
                <h6 className="marginIqamahTimingHeader">
                    IQAMAH<br/>TIMINGS
                </h6>

                <div className="table-responsive" style={{overflow: 'visible'}} >
                    <table className="mx-auto prayerTable">
                        <tbody>
                            {prayers.map(prayer => {
                                return (
                                    <tr key={prayer._id}>
                                        <td align="left">{prayer.name}</td>
                                        <td align="right">{prayer.time}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    <br/><br/>

                    <a className="gradient-button gradient-button-6 disableAHoverEffect2" rel="noopener noreferrer"
                        href={timetable.link} target="_blank">
                        Prayer Timetable
                    </a>
                </div>
            </CardBody>
        </Card>
    );
    
}

PrayerTimings.propTypes = {
    getPrayers: PropTypes.func.isRequired,
    getTimetable: PropTypes.func.isRequired,
    prayers: PropTypes.array.isRequired,
    timetable: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    prayers: state.prayers.prayers,
    timetable: state.prayers.timetable
});

export default connect(
    mapStateToProps,
    { getTimetable, getPrayers }
)(PrayerTimings);