export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const ADMIN_LOADED = 'ADMIN_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT';
export const ANNOUNCEMENT_ERROR = 'ANNOUNCEMENT_ERROR';

export const GET_PRAYERS = 'GET_PRAYERS';
export const GET_PRAYER = 'GET_PRAYER';
export const ADD_PRAYER_SUCCESS = 'ADD_PRAYER_SUCCESS';
export const ADD_PRAYER_FAIL = 'ADD_PRAYER_FAIL';
export const EDIT_PRAYER_SUCCESS = 'EDIT_PRAYER_SUCCESS';
export const EDIT_PRAYER_FAIL = 'EDIT_PRAYER_FAIL';
export const DELETE_PRAYER_SUC = 'DELETE_PRAYER_SUC';
export const DELETE_PRAYER_FAIL = 'DELETE_PRAYER_FAIL';
export const PRAYERS_ERROR = 'PRAYERS_ERROR';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const GET_TABLE = 'GET_TABLE';

export const GET_ADMINS = 'GET_ADMINS';
export const ADMINS_ERROR = 'ADMINS_ERROR';

export const GET_AUDIOS = 'GET_AUDIOS';
export const GET_AUDIO = 'GET_AUDIO';
export const ADD_AUDIO = 'ADD_AUDIO';
export const DELETE_AUDIO = 'DELETE_AUDIO';
export const UPDATE_AUDIO = 'UPDATE_AUDIO';
export const AUDIOS_ERROR = 'AUDIOS_ERROR';

export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';
export const ADD_BLOG = 'ADD_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const BLOG_ERROR = 'BLOG_ERROR';

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';

export const RESET_TOKEN_SUCCESS = 'RESET_TOKEN_SUCCESS';
export const RESET_TOKEN_ERROR = 'RESET_TOKEN_ERROR';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';

export const GET_OVERVIEW = 'GET_OVERVIEW';
export const OVERVIEW_ERROR = 'OVERVIEW_ERROR';

export const GET_PAGES = 'GET_PAGES';
export const GET_PAGE = 'GET_PAGE'
export const PAGE_FAIL = 'PAGE_FAIL';
export const UPDATE_PAGE = 'UPDATE_PAGE'