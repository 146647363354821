import React, { useState } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Breadcrumb, Row, Col, Label,
    Card, CardHeader, CardBody, Input
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addPrayer } from '../../../actions/prayers';
import { setAlert } from '../../../actions/alert';

const AddPrayer = ({ addPrayer }) => {

    const [formData, setFormData] = useState({
        name: '',
        time: '',
    });

    const { name, time } = formData;

    const onChange = e =>
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        addPrayer( name, time);
    }

    return(
        <div>
            <SectionHeader title="Add Prayer" buttonLabel="Back" buttonLink="/admin/prayers" />

            <Container>
                <Breadcrumb>Add Prayer</Breadcrumb>
            </Container>

            <Container>
                <Row>
                    <Col lg="3">

                    </Col>
                    <Col lg="9">
                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Add Prayer</h2>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={e => onSubmit(e)}>
                                    <Label for="name">Prayer Name</Label>
                                    <Input
                                        type="text" className="registerInput" 
                                        value={name} name="name" onChange={e => onChange(e)} /><br/>
                                    
                                    
                                    <Label for="time">Prayer Time</Label>
                                    <Input
                                        type="text" className="registerInput" 
                                        value={time} name="time" onChange={e => onChange(e)} />
                                    
                                    <input type="submit" className="createBtn" value="Add" />

                                </form>

                                <Notify />

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}


AddPrayer.propTypes = {
    addPrayer: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProp = state => ({});


export default connect(
    mapStateToProp,
    { setAlert, addPrayer }
)(AddPrayer);