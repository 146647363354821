import React, { useEffect } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import { 
    Container, Breadcrumb, Col,
    Card, CardBody
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 
import { getAnnouncement } from '../../../actions/announcement';

const Announcement = ({ getAnnouncement, announcement }) =>{

    useEffect(() => {
        getAnnouncement();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <SectionHeader title="Announcement" buttonLabel="Edit" buttonLink="/admin/announcement/edit" />

            <Container>
                <Breadcrumb>Announcement</Breadcrumb>
            </Container>

            <Container style={{marginBottom: 100}}>
                <Col sm="12" md={{ size: 10, offset: 1 }} >
                    <Card className="announceCard">
                        <CardBody style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>
                            <div dangerouslySetInnerHTML={{ __html: announcement.body }} />
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </div>
    )
}

Announcement.propTypes = {
    getAnnouncement: PropTypes.func.isRequired,
    announcement: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    announcement: state.announcement.announcement
});

export default connect(
    mapStateToProps,
    { getAnnouncement }
)(Announcement);