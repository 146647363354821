import React, { Component } from 'react';
import Axios from "axios";
import {Card, CardBody, Button, Input, Alert } from 'reactstrap';

class MailChimpForm extends Component {

    constructor(props) {
        super(props);

        this.subscribeToMailChimp = this.subscribeToMailChimp.bind(this);
        this.onDismiss = this.onDismiss.bind(this);

        this.state = {visible: 'hidden'};
    }

    onDismiss(){
        this.setState({visible: 'hidden'});
    }

    subscribeToMailChimp(event){
        event.preventDefault();
        
        const body = new FormData(event.target);
        const name = body.get('name');
        const email = body.get('email');

        Axios({
          method: "POST",
          url: "/api/subscribe",
          data: { name, email },
          headers: {
            "Content-Type": "application/json"
          }
        }).then(res => {
            console.log('SUCCESS');
            this.setState({visible: 'visible'});
            this.form && this.form.reset();
        }).catch(err => {
            console.log('FAILED TO SUBSCRIBE');
            this.form && this.form.reset();
        });
    };

    render() {
        return(
            <Card style={{backgroundColor: 'transparent', border: 0}} >
                <CardBody>
                        <form onSubmit={this.subscribeToMailChimp} ref={c => (this.form = c)}>
                            <h5 style={{fontFamily: 'Raleway, sans-serif', paddingBottom: 5}}>
                                Subscribe To Our Newsletter
                            </h5>

                            <Input
                                type="text" 
                                id="name" 
                                name="name"
                                className="form-control subscribeInput" 
                                placeholder="Your Name"
                                required />
                            
                            <Input
                                type="email" 
                                id="email" 
                                name="email"
                                className="form-control subscribeInput" 
                                placeholder="Your Email"
                                required />

                            <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
                                <input type="text" name="b_2a7840fd6ff13b203184ce871_91b6564b1e" tabIndex="-1" value="" readOnly />
                            </div>

                            <Button className="subscribeButton" >
                                Subscribe
                            </Button>

                            <Alert color="success" style={{ visibility: this.state.visible}} toggle={this.onDismiss} >
                                You have successfully subscribed!
                            </Alert>
                        </form>
                </CardBody>
            </Card>
        )
    }
}

export default MailChimpForm;