import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect} from 'react-redux';
import { Col, Row, Card, CardBody, CardFooter, Label, Input, Container } from 'reactstrap';
import { getAudios } from '../../../actions/audios';
import AudioFilter from './AudioFilter';

class Audios extends Component {

    constructor(props){
        super(props);

        this.state = {
            speaker: '',
            speakertmp: '',
            topic: '',
            topictmp: ''
        }

        props.getAudios(null, '', '');
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = e => {
        e.preventDefault();

        this.setState({
            speaker: this.state.speakertmp,
            topic: this.state.topictmp
        });
    }

    onReset = e => {
        e.preventDefault();

        this.setState({
            speaker: '',
            speakertmp: '',
            topic: '',
            topictmp: ''
        });
    }


    render(){
        
        let count = 0;

        const topics = [...new Set(this.props.audios.map(audio => audio.topic))];
        const speakers = [...new Set(this.props.audios.map(audio => audio.speaker))];

        return(
            <Container style={{marginBottom: 100, marginTop: 75}}>
                <Row>
                    <Col lg="12" style={{marginBottom: 50}}>
                        <Card className="filterBox">
                            <form onSubmit={e => this.onSubmit(e)}>
                                <CardBody>
                                        <Row>
                                            <Col xs="12" sm="6" md="4" >
                                                <Label for="speakertmp" className="text-muted">Speakers:</Label>
                                                <Input type="select" name="speakertmp" className="filterSelect" value={this.state.speakertmp} onChange={e => this.onChange(e)}>
                                                    <option value="" className="text-muted">Select Speaker</option>
                                                    {speakers.map(speaker => {
                                                        return <option key={++count} value={speaker}>{speaker}</option>
                                                    })}
                                                </Input>
                                            </Col>
                                            <Col xs="12" sm="6" md="4" >
                                                <Label for="topictmp" className="text-muted">Topics:</Label>
                                                <Input type="select" name="topictmp" className="filterSelect" value={this.state.topictmp} onChange={e => this.onChange(e)}>
                                                    <option value="" className="text-muted">Select Topic</option>
                                                    {topics.map(topic => {
                                                        return <option key={++count} value={topic}>{topic}</option>
                                                    })}
                                                </Input>
                                            </Col>
                                        </Row>
                                </CardBody>
                                <CardFooter className="filterBoxFooter">
                                    <button className="filterResetBtn" onClick={e => this.onReset(e)}>RESET</button>
                                    <input type="submit" className="filterBoxBtn" value="FILTER" />
                                </CardFooter>
                            </form>
                        </Card>
                    </Col>

                    <AudioFilter audios={this.props.audios} speaker={this.state.speaker} topic={this.state.topic} />
                </Row>
            </Container>
        )
    }
}

Audios.propTypes = {
    getAudios: PropTypes.func.isRequired,
    audios: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    audios: state.audios.audios
});

export default connect(
    mapStateToProps,
    { getAudios }
)(Audios);