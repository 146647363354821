import { 
    GET_AUDIOS,
    UPDATE_AUDIO,
    DELETE_AUDIO,
    AUDIOS_ERROR, 
    ADD_AUDIO, GET_AUDIO
} from '../actions/types';

const initialState = {
    audios: [],
    audio: {},
    loading: true
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_AUDIOS:
            return {
                ...state,
                audios: payload,
                loading: false
            }
        case GET_AUDIO:
            return {
                ...state,
                audio: payload,
                loading: false
            }
        case UPDATE_AUDIO:
        case DELETE_AUDIO:
        case ADD_AUDIO:
            return {
                ...state,
                loading: false
            }
        case AUDIOS_ERROR:
            return{
                ...state,
                loading: false
            }
        default:
            return state;
    }
}