import React from 'react';
import AppNavbar from './AppNavbar';
import {
    Container,
    Row,
    Col,
    Form
} from 'reactstrap';

function Header(){
    return(
        <div>
            <div className="header" style={{overflow: 'hidden'}}>
                <Container style={{padding: '8px'}}>
                    <Row>
                        <Col lg="9" className="my-auto">
                            <div className="contact-info hideElem">
                                <span>
                                    <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;
                                    (224) 592-0650 &nbsp;&nbsp; |
                                </span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;
                                    masjidsunnahchi@gmail.com &nbsp;&nbsp; |
                                </span>
                                <span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
                                    5144 Main St, Skokie, IL 60077
                                </span>
                            </div>
                        </Col>
                        <Col lg="3" className="text-center">
                            <Form action="https://www.paypal.com/cgi-bin/webscr" method="post" >
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="JCN7DQU4WM7SA" />
                                <input type="submit" value="Donate" name="submit" title="PayPal - The safer, easier way to pay online!" 
                                    className="gradient-button gradient-button-5" />
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AppNavbar />
        </div>
    );
}

export default Header;