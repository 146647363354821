import React, { Component } from 'react';
import Axios from 'axios';
import { Card, Col, Row, Container, CardTitle, Input, Label, Button, Alert } from 'reactstrap';
import Divider from '../layout/Divider';

class Contact extends Component {

    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {visible: 'hidden'};
    }

    handleSubmit(event) {
        event.preventDefault();

        const body = new FormData(event.target);
        const name = body.get('name');
        const email = body.get('email');
        const tel = body.get('tel');
        const message = body.get('message');

        Axios({
          method: "POST",
          url: "http://localhost:5000/api/send",
          data: { name, email, tel, message },
          headers: {
            "Content-Type": "application/json"
          }
        }).then(res => {
          if (res.data.msg === "suc") {
            console.log('SUCCESS');
            this.setState({visible: 'visible'});
            this.form && this.form.reset();
          }
        }).catch(err => {
            console.log('FAILED TO SEND MESSAGE');
            this.form && this.form.reset();
        });
    }

    render() {
        return(
            <div>
                <div className="site-blocks-cover overlay" data-stellar-background-ratio="0.5" data-aos="fade">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2963.4350288129426!2d-87.75871068425893!3d42.03385096363122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcf1580e69505%3A0x2e8a0dd3a91d4461!2sMasjid%20As-Sunnah%20Chicago!5e0!3m2!1sen!2sus!4v1584678462130!5m2!1sen!2sus" 
                        title="Masjid As-Sunnah Chicago Location" width="100%" height="100%" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0">
                    </iframe>
                </div>

                <div style={{marginTop: -75}}>
                    <Divider d="M 0 0 L 502.5 75 L 1005 0 L 1005 75 L 0 75 Z" fill="#FFFFFF" stroke="#FFFFFF" position="relative" />
                </div>

                <Container>
                    <Card className="contactCard">
                        <Row>
                            <Col lg="8">
                                <CardTitle> <h2>SEND US A MESSAGE</h2> </CardTitle>

                                <form onSubmit={this.handleSubmit} ref={c => (this.form = c)}>
                                    <Row>
                                        <Col md="12">
                                            <div className="md-form mb-0" >
                                                <Input type="text" id="name" name="name" className="form-control contactInput"  required />
                                                <Label for="name">Full Name *</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6">
                                            <div className="md-form mb-0">
                                                <Input type="email" id="email" name="email" className="form-control contactInput"  required />
                                                <Label for="email">Email *</Label>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="md-form mb-0">
                                                <Input type="tel" id="tel" name="tel" className="form-control contactInput" />
                                                <Label for="phone">Phone</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <div className="md-form">
                                                <Input type="textarea" id="message" name="message" rows="5" className="form-control md-textarea contactInput" required />
                                                <Label for="message">Message *</Label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="text-center text-md-left">
                                        <Button className="gradient-button gradient-button-4 sendButton">
                                            SEND
                                        </Button>
                                    </div>

                                    <Alert color="success" style={{ visibility: this.state.visible}} toggle={this.onDismiss} >
                                        Your message was sent successfully, Thanks!
                                    </Alert>
                                </form>

                            </Col>
                            <Col lg="4" className="my-auto" style={{padding: 10}}>
                                <h3 style={{marginBottom: 12, marginLeft: 5}}>Contact Details</h3>

                                <div style={{fontFamily: 'Raleway, sans-serif'}}>
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-map-marker fa-stack-1x"></i>
                                    </span> &nbsp;
                                    5144 Main St, Skokie, IL 60077
                                </div>
                                <div style={{fontFamily: 'Raleway, sans-serif'}}>
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-phone fa-stack-1x"></i>
                                    </span> &nbsp;
                                    +1 (224) 592-0650
                                </div>
                                <div style={{fontFamily: 'Raleway, sans-serif'}}>
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-envelope fa-stack-1x"></i>
                                    </span>&nbsp;
                                    masjidsunnahchi@gmail.com
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>

            </div>
        );
    }
}

export default Contact;