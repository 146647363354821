import {
    GET_BLOG,
    GET_BLOGS,
    ADD_BLOG,
    UPDATE_BLOG,
    DELETE_BLOG,
    BLOG_ERROR
} from '../actions/types';

const initialState = {
    blogs: [],
    blog: {},
    loading: true
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_BLOGS:
            return {
                ...state,
                blogs: payload,
                loading: false
            }
        case GET_BLOG:
            return {
                ...state,
                blog: payload,
                loading: false
            }
        case UPDATE_BLOG:
        case DELETE_BLOG:
        case ADD_BLOG:
            return {
                ...state,
                loading: false
            }
        case BLOG_ERROR:
            return{
                ...state,
                loading: false
            }
        default:
            return state;
    }
}