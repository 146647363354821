import React, { useEffect } from 'react';
import BlogCard from './BlogCard';
import Twitter from '../layout/Twitter';
import { Container, Col, Row} from 'reactstrap';
import { getBlogs } from '../../actions/blog';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const BlogSection = ({getBlogs, blogs}) => {

    useEffect(() => {
        getBlogs(4);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className="blogsection">
            <Container>
                <div className="title-lines">
                    <h1 style={{fontSize: 36}}>
                        Recent Posts
                    </h1>
                </div>
            </Container>

            <Container style={{paddingBottom: 100}}>
                <Row>
                    <Col lg="8" style={{marginBottom: 20}}>
                        {blogs.map(blog => {
                            return <BlogCard key={blog._id} blog={blog} />
                        })}
                    </Col>
                    <Col lg="4">
                        <div style={{marginLeft: 10, marginRight: 10}}>
                            <h3> Follow Us On Twiiter </h3>
                            <Twitter/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}


BlogSection.propTypes = {
    getBlogs: PropTypes.func.isRequired,
    blogs: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    blogs: state.blog.blogs
});

export default connect(
    mapStateToProps,
    {getBlogs}
)(BlogSection);