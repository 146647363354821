import React, { Component } from 'react';

class NotFound extends Component {

    
    componentDidMount = () => {
        this.props.hideNavigation();
        this.props.hideFooter();
    }

    render() {
        return(
            <div>
                <h3>404 page not found</h3>
                <p>We are sorry but the page you are looking for does not exist.</p>
            </div>
        );
    }
}

export default NotFound;