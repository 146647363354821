import Axios from 'axios';
import {
    ADD_AUDIO,
    UPDATE_AUDIO,
    DELETE_AUDIO,
    GET_AUDIOS,
    GET_AUDIO,
    AUDIOS_ERROR
} from './types';
import { setAlert } from './alert';

export const getAudios = (limit, speaker, topic ) => async dispatch => {
    try {
        const res = await Axios.get('/api/audios', { params: { limit: limit, speaker: speaker, topic: topic } });

        dispatch({
            type: GET_AUDIOS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: AUDIOS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const getAudio = (id, history) => async dispatch => {
    try{
        const res  = await Axios.get(`/api/audios/${id}`);

        dispatch({
            type: GET_AUDIO,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: AUDIOS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

        dispatch(setAlert('Could not find audio', 'danger'));

        history.push('/admin/audios');
    }
}

export const addAudio = (title, speaker, topic, thumbnail, content, summary, history ) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    let data =  new FormData();
    if(thumbnail)
        data.append('thumbnail', thumbnail, thumbnail.name);

    data.append('title', title);
    data.append('speaker', speaker);
    data.append('topic', topic);
    data.append('content', content);
    data.append('summary', summary);

    try {
        const res = await Axios.post('/api/audios', data, config);

        dispatch({
            type: ADD_AUDIO,
            payload: res.data
        });

        dispatch(setAlert('Audio successfully added', 'success'));

        history.push('/admin/audios');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: AUDIOS_ERROR
        });
    }
}

export const editAudio = (id, title, speaker, topic, thumbnail, content, summary, history ) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    let data =  new FormData();
    if(thumbnail)
        data.append('thumbnail', thumbnail, thumbnail.name);

    data.append('title', title);
    data.append('speaker', speaker);
    data.append('topic', topic);
    data.append('content', content);
    data.append('summary', summary);

    try {
        const res = await Axios.post(`/api/audios/${id}`, data, config);

        dispatch({
            type: UPDATE_AUDIO,
            payload: res.data
        });

        dispatch(setAlert('Audio successfully updated', 'success'));

        history.push('/admin/audios');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: AUDIOS_ERROR
        });
    }
}

export const deleteAudio = (id) => async dispatch => {
    try{
        const res = await Axios.delete(`/api/audios/${id}`);

        dispatch({
            type: DELETE_AUDIO,
            payload: res.data
        });

        dispatch(getAudios());
        dispatch(setAlert('Successfully deleted item', 'success'));
    } catch( err ){
        dispatch({
            type: AUDIOS_ERROR
        });

        dispatch(setAlert('Failed to delete item', 'danger'));
    }
}