import React, { useState } from 'react';
import { Container, Row, Col, Card, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendResetLink } from '../../actions/admins';
import { setAlert } from '../../actions/alert';
import { Link } from 'react-router-dom';
import Notify from '../Notify';

const Forgot = ({ sendResetLink }) =>{
    
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => 
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        sendResetLink(email);
    }

    return(
        <div className="loginPage">
            <Container className="loginContainer">
                <Row style={{height: '100%'}}>
                    <Col className="my-auto text-center" lg={{size: '6', offset: '3'}}>
                        <Card className="loginCard">

                            <Notify />

                            <img src={require('../../assets/images/masjid-logo.png')} alt="masjid logo" className="img-fluid masjid-logo" style={{maxWidth: 125}} />

                            <h2 className="loginHeader">Forgot Password</h2>

                            <form onSubmit={e => onSubmit(e)}>
                                <Input
                                    type="email" name="email" className="form-control loginInput" 
                                    onChange={e => onChange(e)} placeholder="Email" />

                                <button className="loginBtn">
                                    Send Verification
                                </button>
                            </form>

                            <div style={{marginTop: 50}}>
                                <Link to="/admin" className="loginLink">Back</Link>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

Forgot.propTypes = {
    sendResetLink: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

});

export default connect(
    mapStateToProps,
    {sendResetLink, setAlert}
)
(Forgot);

