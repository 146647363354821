import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

const Notify = ({ alerts }) => 
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map(alert => {
        return(
            <Alert className="notify" key={alert.id} color={alert.alertType} >
                {alert.msg}
            </Alert>
        );
    });

Notify.propTypes = {
    alerts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    alerts: state.alert
});

export default connect(mapStateToProps)(Notify);