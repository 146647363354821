import React, { useEffect } from 'react';
import {
    Container, Row, Col
} from 'reactstrap';
import { getBlog } from '../../../actions/blog';
import { connect }  from 'react-redux';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const BlogView = ({ getBlog, blog, match }) => {
    
    useEffect(() => {
        getBlog(match.params.id);
        window.scrollTo(0, 0);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Container style={{marginTop: 50, marginBottom: 200}}>
            <Row>
                <Col lg="8">
                    
                    <h2>{blog.title}</h2>
                    
                    <hr style={{marginTop: '.75rem', marginBottom: '1rem'}}/>

                    { ReactHtmlParser(blog.content) }
                </Col>
                <Col lg="4">

                </Col>
            </Row>
        </Container>
    );
}

BlogView.propTypes = {
    getBlog: PropTypes.func.isRequired,
    blog: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    blog: state.blog.blog
})

export default connect(
    mapStateToProps,
    { getBlog }
)(BlogView);