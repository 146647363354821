import React, { useState } from 'react';
import { 
    Container, Input, Label, Breadcrumb, 
    Card, CardHeader, CardBody, Col
} from 'reactstrap';
import SectionHeader from '../cmslayout/SectionHeader';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { updateTable } from '../../../actions/prayers';
import Notify from '../../Notify';

const UpdateTable = ({ updateTable, history }) => {
    
    const [formData, setFormData] = useState({
        timetable: null
    });

    const { timetable } = formData;

    const onChange = e => {
        setFormData({timetable: e.target.files[0]});
    }

    const onSubmit = async e => {
        e.preventDefault();
        updateTable(timetable, history);
    }

    return(
        <div style={{marginBottom: 100}}>
            <SectionHeader title="Timetable" buttonLabel="Back" buttonLink="/admin/timetable" />

            <Container>
                <Breadcrumb>Upload Timetable</Breadcrumb>
            </Container>

            <Container>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                    <Card>
                        <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                            <h2 style={{color: '#fff'}}>Upload New Timetable</h2>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={e => onSubmit(e)}>
                                <Label for="name">Upload New Timetable</Label>
                                <Input
                                    type="file" className="registerInput" 
                                    name="timetable" onChange={e => onChange(e)} />
                                
                                <div style={{textAlign: 'right'}}>
                                    <input type="submit" className="createBtn" value="Upload" />
                                </div>
                            </form>
                            
                            <Notify />

                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </div>
    );
}

UpdateTable.propTypes = {
    setAlert: PropTypes.func.isRequired,
    updateTable: PropTypes.func.isRequired
}

export default connect(
    null,
    { setAlert, updateTable }
)(withRouter(UpdateTable));