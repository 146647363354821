import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Container
} from 'reactstrap';

class AppNavbar extends Component {

    state = {
        isOpen: false
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    closeNavbar = () => {
        if (this.state.isOpen && window.innerWidth < 768 )
            this.toggle();
    }

    render() {
        return(
            <Navbar light expand="md" id="navbar" className="appNavbar" >
                <Container>
                    <Link onClick={this.closeNavbar} className="appNavBrand" to="/">
                        <img src={require('../../assets/images/masjid-logo.svg')} alt="logo" width="150" height="40" />
                    </Link>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/about">
                                    About
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/audios" >
                                    Audios
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/blog" >
                                    Blog
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/contact">
                                    Contact
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/donate">
                                    Donate
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link onClick={this.closeNavbar} className="navLink" to="/resources">
                                    Resources
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        )
    }

}

export default AppNavbar;