import React from 'react';
import MailChimpForm from '../MailChimpForm';
import { 
    Container, Col, Row, Card, 
    CardBody, CardTitle, CardText
} from 'reactstrap';

function Footer(){
        
    // get the current year
    const currYear = new Date().getFullYear();

    return (
        <div className="footer" id="coverFooter">
            <div id="coverFooterPartOne" ></div>

            <Container style={{marginTop: 10, marginBottom: 10}}>
                <Row>
                    <Col lg="4">
                        <Card style={{backgroundColor: 'transparent', border: 'none'}}>
                            <img src={require('../../assets/images/masjid-logo.png')} alt="masjid logo" className="img-fluid masjid-logo" />
                        </Card>
                    </Col>
                    
                    <Col lg="4">
                        <MailChimpForm />
                    </Col>
                    
                    <Col lg="4">
                        <Card style={{backgroundColor: 'transparent', border: 0}}>
                            <CardBody>
                                <CardTitle>
                                    <h5 style={{fontFamily: 'Raleway, sans-serif', color: '#333333'}}>
                                        About Us
                                    </h5>
                                </CardTitle>
                                
                                <CardText style={{fontFamily: 'Raleway, sans-serif'}}>
                                    Masjid As-Sunnah is a mixed and diverse multi-cultural masjid located 
                                    in Skokie, a village in Cook County, Illinois, United States, neighboring 
                                    the City of Chicago's northern border...
                                </CardText>

                                <a className="gradient-button gradient-button-5 disableAHoverEffect2" 
                                    style={{fontSize: 14, margin: 0, fontFamily: 'Merriweather, sans-serif', fontWeight: 'bold'}}
                                    href="/about">
                                    Read More
                                </a>

                                <br/><br/>

                                <a className="disableAHoverEffect2" style={{color: '#333333'}} rel="noopener noreferrer"
                                    href="https://www.facebook.com/Masjid-As-Sunnah-Chicago-1461923917233090/" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-facebook fa-stack-1x"></i>
                                    </span>
                                </a>
                                <a className="disableAHoverEffect2" style={{color: '#333333'}} rel="noopener noreferrer"
                                    href="https://twitter.com/masjidsunnahchi" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-twitter fa-stack-1x"></i>
                                    </span>
                                </a>
                                <a className="disableAHoverEffect2" style={{color: '#333333'}} rel="noopener noreferrer"
                                    href="http://telegram.me/Masjidassunnahchicago" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-paper-plane fa-stack-1x"></i>
                                    </span>
                                </a>
                                <a className="disableAHoverEffect2" style={{color: '#333333'}} rel="noopener noreferrer"
                                    href="https://www.youtube.com/channel/UC1iGJ3SxdepMwOqQmo4B1eg" target="_blank">
                                    <span className="fa-stack fa-lg">
                                        <i className="fa fa-square-o fa-stack-2x"></i>
                                        <i className="fa fa-youtube fa-stack-1x"></i>
                                    </span>
                                </a>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div style={{backgroundColor: 'transparent', paddingBottom: 14, justifyContent: 'center'}} >
                <hr style={{marginLeft: 45, marginRight: 45}} />
                <h6 style={{color: '#333333', margin: 'auto', textAlign: 'center'}} >
                    &copy; {currYear} NORTHSHORE ISLAMIC CENTER NFP
                </h6>
            </div>
        </div>
    );
}

export default Footer;