import {
    RESET_TOKEN_SUCCESS,
    RESET_TOKEN_ERROR,
    RESET_SUCCESS,
    RESET_FAIL
} from './types';
import { setAlert } from './alert';
import Axios from 'axios';

export const checkResetToken = (token) => async dispatch => {

    try {
        const res = await Axios.get(`/api/reset/${token}`);

        dispatch({
            type: RESET_TOKEN_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        
        dispatch(setAlert('Password reset token is invalid or has expired', 'danger'));
        
        dispatch({
            type: RESET_TOKEN_ERROR
        });
    }
}

export const resetPassword = (token, password, confirmPassword) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ password, confirmPassword });

    try {
        const res = await Axios.post(`/api/reset/${token}`, body, config);

        dispatch({
            type: RESET_SUCCESS,
            payload: res.data
        });
        
        dispatch(setAlert('Password has been successfully reset', 'success'));

    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        
        dispatch({
            type: RESET_FAIL
        });
    }
}