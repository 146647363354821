import React, { useEffect } from 'react';
import {
    Container, Col, Row
} from 'reactstrap';
import { getAudio } from '../../../actions/audios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AudioView  = ({ getAudio, audio, match }) => {
    useEffect(() => {
        getAudio(match.params.id);
        window.scrollTo(0, 0);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Container style={{marginTop: 50, marginBottom: 100}}>
            <Row>
                <Col lg="8">
                    
                    <h2 >{audio.title}</h2>
                    
                    <hr style={{marginTop: '.75rem', marginBottom: '1rem'}}/>

                    <div className="iframe-container" dangerouslySetInnerHTML={{ __html: audio.content }} />

                    <p className="audioViewSum" >{audio.summary}</p>
                </Col>
                <Col lg="4">

                </Col>
            </Row>
        </Container>
    );
}

AudioView.propTypes = {
    getAudio: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    audio: state.audios.audio
})

export default connect(
    mapStateToProps,
    { getAudio }
)(AudioView);