import React, { useEffect } from 'react';
import {
    Container, Breadcrumb
} from 'reactstrap';
import SectionHeader from '../cmslayout/SectionHeader';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { getTimetable } from '../../../actions/prayers';

const Timetable = ({getTimetable, timetable}) => {

    useEffect(() => {
        getTimetable();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{marginBottom: 100}}>
            <SectionHeader title="Timetable" buttonLabel="Update" buttonLink="/admin/timetable/update" />

            <Container>
                <Breadcrumb>Timetable</Breadcrumb>
            </Container>

            <Container>
                <Notify />

                <object data={timetable.link} type='application/pdf' width="100%" height="600px" >
                    <p>This browser does not support PDFs. Please download the PDF to view it: <a href={timetable.link} target="_blank" rel="noopener noreferrer">Download PDF</a>.</p>
                </object>
            </Container>
        </div>
    );
}


Timetable.propTypes = {
    setAlert: PropTypes.func.isRequired,
    getTimetable: PropTypes.func.isRequired,
    timetable: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    timetable: state.prayers.timetable
});

export default connect(
    mapStateToProps,
    { getTimetable, setAlert }
)(Timetable);