import { 
    RESET_TOKEN_SUCCESS,
    RESET_TOKEN_ERROR,
    RESET_SUCCESS,
    RESET_FAIL,
} from "../actions/types";

const initialState = {
    loading: true,
    isResetTokenValid: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case RESET_TOKEN_SUCCESS:
            return {
                ...state,
                isResetTokenValid: true,
                loading: false
            }
        case RESET_TOKEN_ERROR:
            return{
                ...state,
                errors: payload,
                isResetTokenValid: false,
                loading: false
            }
        case RESET_FAIL:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        case RESET_SUCCESS:
            return{
                ...state,
                isResetTokenValid: false,
                loading: false
            }
        default:
            return state;
    }
}