import React, { Component } from 'react';
import Login from './Login';
import Reset from './Reset';
import Register from './Register';
import Dashboard from './Dashboard';
import Forgot from './Forgot';
import Blog from './blog/Blog';
import AddPost from './blog/AddPost';
import EditPost from './blog/EditPost';
import Prayers from './prayers/Prayers';
import CmsAudios from './audios/CmsAudios';
import AddAudio from './audios/AddAudio';
import EditAudio from './audios/EditAudio';
import { loadUser } from '../../actions/auth';
import { BrowserRouter as Router, Route, Switch } from  'react-router-dom';
import store from '../../store';
import setAuthToken from '../../utils/setAuthToken';
import CmsNav from './cmslayout/CmsNav';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NotFound from '../NotFound';
import PrivateRoute from './PrivateRoute';
import EditPrayer from './prayers/EditPrayer';
import AddPrayer from './prayers/AddPrayer';
import Timetable from './timetable/Timetable';
import UpdateTable from './timetable/UpdateTable';
import Announcement from './announcement/Announcement';
import EditAnnouncement from './announcement/EditAnnouncement';
import Page from './pages/Page';
import EditPage from './pages/EditPage';

if (localStorage.token){
    setAuthToken(localStorage.token);
}

class Admin extends Component {

    constructor (props) {
        super(props)
        this.state = {
            showCmsNav: true
        }
    }

    componentDidMount = () =>{
        this.props.hideNavigation();
        this.props.hideFooter();

        store.dispatch(loadUser());
    }

    hideCmsNav = () => {
        this.setState({showCmsNav: false});
    }

    render(){
        const { match, isAuthenticated } = this.props;

        return (
            <Router>

                {isAuthenticated ? (this.state.showCmsNav &&  <CmsNav />) : <div></div>}

                <Switch>
                    
                    <Route exact path={match.url} component={Login} />
                    <PrivateRoute exact path={match.url + '/dashboard'} component={Dashboard}/>
                    <PrivateRoute exact path={match.url + '/register'} component={Register}/>
                    
                    <PrivateRoute exact path={match.url + '/announcement'} component={Announcement} />
                    <PrivateRoute exact path={match.url + '/announcement/:id'} component={EditAnnouncement} />

                    <PrivateRoute exact path={match.url + '/audios'} component={CmsAudios}/>
                    <PrivateRoute exact path={match.url + '/audios/add'} component={AddAudio}/>
                    <PrivateRoute exact path={match.url + '/audios/:id'} component={EditAudio}/>

                    <PrivateRoute exact path={match.url + '/blog'} component={Blog}/>
                    <PrivateRoute exact path={match.url + '/blog/add'} component={AddPost}/>
                    <PrivateRoute exact path={match.url + '/blog/:id'} component={EditPost}/>

                    <PrivateRoute exact path={match.url + '/pages/:title'} component={Page} />
                    <PrivateRoute exact path={match.url + '/pages/:title/edit'} component={EditPage} />
                    
                    <PrivateRoute exact path={match.url + '/prayers'} component={Prayers}/>
                    <PrivateRoute exact path={match.url + '/prayers/add'} component={AddPrayer}/>
                    <PrivateRoute exact path={match.url + '/prayers/:id'} component={EditPrayer} />

                    <PrivateRoute exact path={match.url + '/timetable/'} component={Timetable} />
                    <PrivateRoute exact path={match.url + '/timetable/update'} component={UpdateTable} />
                    
                    <Route exact path={match.url + '/forgot'} component={Forgot}/>
                    <Route exact path={match.url + '/reset/:token' } component={Reset}/>

                    <Route 
                        path="*"
                        render={(props) => (
                            <NotFound {...props} hideNavigation={this.hideCmsNav} hideFooter={this.props.hideFooter} />
                        )}
                    />

                </Switch>
            </Router>
        );
    }
}

Admin.propTypes = {
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(
    mapStateToProps
)(Admin);