import React, { useEffect } from 'react';
import Divider from '../layout/Divider';
import { Container, Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAnnouncement } from '../../actions/announcement';

const AnnouncementSection = ({ getAnnouncement, announcement }) => {
    
    useEffect(() => {
        getAnnouncement();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div className="announcementSection" id="announcementSection">
            <Container className="text-center">
                <p className="arabicFont al-bismillah text-center" style={{paddingTop: 20}}>
                  ﷽
                </p>
            </Container>
            <Container style={{paddingBottom: 50}}>
                <Col sm="12" md={{ size: 10, offset: 1 }} >
                    <Card className="announceCard">
                        <CardBody style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>
                            <div dangerouslySetInnerHTML={{ __html: announcement.body }} />
                        </CardBody>
                    </Card>
                </Col>
            </Container>
            <Divider d="M 0 75 Q 110 25 275 30 Q 389 35 503 0 Q 616.5 35 730 35 Q 867.5 25 1005 75" 
                    fill="#FFFFFF" stroke="#FFFFFF" position="relative" top="0"/>
        </div>
    );
}

AnnouncementSection.propTypes = {
    getAnnouncement: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    announcement: state.announcement.announcement
})

export default connect(
    mapStateToProps,
    { getAnnouncement }
)(AnnouncementSection);