import {
    GET_PAGE,
    GET_PAGES,
    PAGE_FAIL,
    UPDATE_PAGE
} from '../actions/types';

const initialState = {
    pages: [],
    page: {},
    loading: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_PAGES:
            return {
                ...state,
                pages: payload,
                loading: false
            }
        case GET_PAGE:
            return {
                ...state,
                page: payload,
                loading: false
            }
        case UPDATE_PAGE:
            return {
                ...state,
                loading: false
            }
        case PAGE_FAIL:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        default:
            return state;
    }
}