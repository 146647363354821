import { 
    GET_PRAYERS, 
    GET_PRAYER,
    PRAYERS_ERROR,
    GET_TABLE,
    UPDATE_TABLE,
    ADD_PRAYER_SUCCESS,
    ADD_PRAYER_FAIL,
    EDIT_PRAYER_SUCCESS,
    EDIT_PRAYER_FAIL,
    DELETE_PRAYER_SUC,
    DELETE_PRAYER_FAIL
} from "../actions/types";

const initialState = {
    prayers: [],
    prayer: {},
    timetable: {},
    loading: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_PRAYERS:
            return {
                ...state,
                prayers: payload,
                loading: false
            }
        case GET_PRAYER:
            return {
                ...state,
                prayer: payload,
                loading: false
            }
        case GET_TABLE:
            return {
                ...state,
                timetable: payload,
                loading: false
            }
        case UPDATE_TABLE:
        case ADD_PRAYER_SUCCESS:
        case EDIT_PRAYER_SUCCESS:
        case DELETE_PRAYER_SUC:
        case DELETE_PRAYER_FAIL:
            return {
                ...state,
                loading: false
            }
        case PRAYERS_ERROR:
        case ADD_PRAYER_FAIL:
        case EDIT_PRAYER_FAIL:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        default:
            return state;
    }
}