import {
    GET_PAGES,
    GET_PAGE,
    PAGE_FAIL,
    UPDATE_PAGE
} from './types';
import Axios from 'axios';
import { setAlert } from './alert';

export const getPages = () => async dispatch => {

    try{
        const res = await Axios.get('/api/pages');

        dispatch({
            type: GET_PAGES,
            payload: res.data
        });
    } catch (err){
        dispatch({
            type: PAGE_FAIL,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const getPage = (title, history, url) => async dispatch => {

    try{
        const res = await Axios.get(`/api/pages/${title}`);

        dispatch({
            type: GET_PAGE,
            payload: res.data
        });
    } catch (err){
        dispatch({
            type: PAGE_FAIL,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

        dispatch(setAlert('Could not find page', 'danger'));

        if(url !== undefined)
            history.push(url);
        else
            history.push(`/admin/pages/${title}`);
    }
}

export const editPage = (id, title, pagebody, history) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ pagebody });

    try {
        const res = await Axios.put(`/api/pages/${id}`, body, config);

        dispatch({
            type: UPDATE_PAGE,
            payload: res.data
        });
        
        dispatch(setAlert('Page successfully updated', 'success'));

        history.push(`/admin/pages/${title}`);
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PAGE_FAIL
        });
    }
}