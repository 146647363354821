import React, { useEffect } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import { 
    Container, Breadcrumb, Col, Row,
    Card, CardHeader, CardBody, Table
} from 'reactstrap';
import Notify from '../../Notify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBlogs, deletePost } from '../../../actions/blog';

const Blog = ({ getBlogs, deletePost, blogs, match}) =>{

    useEffect(() => {
        getBlogs();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const removePost = (e, id) => {
        e.preventDefault();
        if (window.confirm('Are you sure you wish to delete this item?'))
            deletePost(id);
    }

    return(
        <div>
            <SectionHeader title="Blog" buttonLabel="Add Post" buttonLink="/admin/blog/add" />

            <Container>
                <Breadcrumb>Blog</Breadcrumb>
            </Container>

            <Container style={{marginBottom: 100}}>
                <Row>
                    <Col lg="3">

                    </Col>
                    <Col lg="9">
                        <Notify />
                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Blog Posts</h2>
                            </CardHeader>
                            <CardBody>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>Post Title</th>
                                            <th>Category</th>
                                            <th>Summary</th>
                                            <th>Content</th>
                                            <th>Date Posted</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {blogs.map(post => {
                                            return (
                                                <tr key={post._id}>
                                                    <td>{post.title}</td>
                                                    <td>{post.category}</td>
                                                    <td>{post.summary.slice(0,100)+'...'}</td>
                                                    <td>{post.content.slice(0,200)+'...'}</td>
                                                    <td>{post.datePosted}</td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: `${match.url}/${post._id}`
                                                        }} className="editLink">
                                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <button onClick={e => removePost(e, post._id)} className="trashBtn">
                                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

Blog.propTypes = {
    getBlogs: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    blogs: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    blogs: state.blog.blogs
});

export default connect(
    mapStateToProps,
    { getBlogs, deletePost }
)(Blog);