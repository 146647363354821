import React, { useEffect, useState } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Breadcrumb, Col, Label,
    Card, CardHeader, CardBody, Input
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { getAudios, addAudio } from '../../../actions/audios';

const AddAudio = ({ getAudios, addAudio, audios, history }) => {
    
    useEffect(() => {
        getAudios();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [formData, setFormData] = useState({
        title: '',
        speaker: '',
        topic: '',
        thumbnail: null,
        content: '',
        summary: '',
        chars: 300
    });

    const topics = [...new Set(audios.map(x => x.topic))];

    const { title, speaker, topic, thumbnail, content, summary } = formData;

    const onChange = e =>
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        addAudio(title, speaker, topic, thumbnail, content, summary, history);
    }

    const handleFileUpload = e => {
        setFormData({...formData, thumbnail: e.target.files[0]});
    }

    const onSumChange = (e) => {
        let inputLength = 300 - e.target.value.length;
        let input = inputLength === 0 ? formData.summary : e.target.value; 
        setFormData({
            ...formData, 
            summary: input,
            chars: inputLength
        });
    }


    return(
        <div>
            <SectionHeader title="Add Audio" buttonLabel="Back" buttonLink="/admin/audios" />

            <Container>
                <Breadcrumb>Add Audio</Breadcrumb>
            </Container>

            <Container>
                <Col sm="12" md={{ size: 8, offset: 2 }} style={{marginBottom: 100}}>
                    <Card>
                        <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                            <h2 style={{color: '#fff'}}>Add Audio</h2>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={e => onSubmit(e)} >
                                <Label for="title">Audio Title</Label>
                                <Input
                                    type="text" className="registerInput" 
                                    value={title} name="title" onChange={e => onChange(e)} /><br/>
                                
                                <Label for="speaker">Speaker</Label>
                                <Input
                                    type="text" className="registerInput" 
                                    value={speaker} name="speaker" onChange={e => onChange(e)} /><br/>

                                <label htmlFor="topic">Topic:</label><br/>
                                <datalist id="topics">
                                    <select name="topic">
                                        {topics.map(topic => {
                                            return <option key={topic} value={topic}>{topic}</option>;
                                        })}
                                    </select>
                                    If other, please specify:
                                </datalist> &nbsp;
                                <input type="text" name="topic" id="topic" list="topics" 
                                    onChange={e => onChange(e)} value={topic} autoComplete="off" /><br/><br/>

                                <Label for="thumbnail">Thumbnail (optional)</Label>
                                <Input
                                    type="file" className="registerInput" 
                                    name="thumbnail" onChange={e => handleFileUpload(e)} /><br/>

                                <Label for="content">Content (iframe)</Label>
                                <Input
                                    type="text" className="registerInput" 
                                    value={content} name="content" onChange={e => onChange(e)} /><br/>

                                <Label for="summary">Summary</Label>
                                <Input
                                    type="textarea" className="registerInput" rows="5"
                                    value={summary} name="summary" onChange={e => onSumChange(e)} />
                                <p>Characters Left: {formData.chars}</p>
                                
                                <input type="submit" className="createBtn" value="Add" />

                            </form>

                            <Notify />

                        </CardBody>
                    </Card>
                </Col>
            </Container>

        </div>
    )
}


AddAudio.propTypes = {
    getAudios: PropTypes.func.isRequired,
    addAudio: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    audios: PropTypes.array.isRequired
}

const mapStateToProp = state => ({
    audios: state.audios.audios
});


export default connect(
    mapStateToProp,
    { setAlert, getAudios, addAudio}
)(AddAudio);