import React, { Component } from 'react';
import Headline from './Headline';
import AnnounceSection from './AnnounceSection';
import AudioSection from './AudioSection';
import BlogSection from './BlogSection';

class HomePage extends Component {
    render(){
        return (
            <div>
                <Headline />
                <AnnounceSection />
                <AudioSection />
                <BlogSection />
            </div>
        )
    }
}

export default HomePage;