import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBlogs } from '../../../actions/blog';
import { Col, Container } from 'reactstrap';
import BlogItem from './BlogItem'

class Blog extends Component {

    constructor(props){
        super(props);

        props.getBlogs();
    }

    render() {
        return(
            <Container style={{marginBottom: 100, marginTop: 75}}>
                {this.props.blogs.map(blog => {
                    return(
                        <Col lg={{ size: 8, offset: 2 }} key={blog._id} className="d-flex justify-content-center">
                            <BlogItem blog={blog}/>
                        </Col>
                    );
                })}
            </Container>
        )
    }
}

Blog.propTypes = {
    getBlogs: PropTypes.func.isRequired,
    blogs: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    blogs: state.blog.blogs
});

export default connect(
    mapStateToProps,
    { getBlogs }
)(Blog);