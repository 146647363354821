import React from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';

function AudioItem(props) {

    const thumbImg = props.audio.thumbnail === 'default.png' ? require('../../../assets/uploads/default.png') : props.audio.thumbnail;

    return(
        <Link to={'/audios/'+props.audio._id}>
            <Card className="audioItem">
                <img className="audioItemImg" src={thumbImg} alt="thumbnail" />
            </Card>
        </Link>
    );
}


export default AudioItem;
