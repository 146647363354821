import React, { Fragment, useState } from 'react';
import { Container, Col, Row, Card, Input} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import Notify from '../Notify';

const Login = ({ login, isAuthenticated }) =>{
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const { email, password } = formData;

    const onChange = e => 
        setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        login(email, password);
    }

    // Redirect if logged in
    if(isAuthenticated) {
        return <Redirect to='/admin/dashboard' />;
    }

    return(
        <Fragment>
            <div className="loginPage">
                <Container className="loginContainer">
                    <Row style={{height: '100%'}}>
                        <Col className="my-auto text-center" lg={{size: '6', offset: '3'}}>
                            <Card className="loginCard">

                                <Notify />

                                <img src={require('../../assets/images/masjid-logo.png')} alt="masjid logo" className="img-fluid masjid-logo" style={{maxWidth: 125}} />

                                <h2 className="loginHeader">Admin Login</h2>

                                <form onSubmit={e => onSubmit(e)}>
                                    <Input
                                        type="email" name="email" value={email}  className="form-control loginInput" 
                                        onChange={e => onChange(e)} placeholder="Email" required />

                                        
                                    <Input
                                        type="password" name="password" className="form-control loginInput" 
                                        value={password} onChange={e => onChange(e)} placeholder="Password" required />

                                    <button className="loginBtn" value='Login'>
                                        Login
                                    </button>
                                </form>

                                <div style={{marginTop: 50}}>
                                    <Link to="/admin/forgot" className="loginLink">Forgot password?</Link>
                                </div>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(
    mapStateToProps, 
    { login, setAlert })
(Login);