import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';

function SectionHeader(props){
    return(
        <div style={{backgroundColor: '#333333'}}>
            <Container style={{paddingTop: 20, paddingBottom: 20, marginBottom: 15}}>
                <Row>
                    <Col lg="10">
                        <h1 style={{color: '#fff'}}> 
                            <i className="fa fa-cog" aria-hidden="true"></i> &nbsp;
                            {props.title}
                        </h1>
                    </Col>

                    <Col lg="2" className="my-auto" style={{textAlign: 'right'}}>
                        <Link className="sectionBtn" to={props.buttonLink}>{props.buttonLabel}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SectionHeader;