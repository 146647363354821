import React from 'react';
import {
    Card, CardBody, CardImg, 
    CardText, Col, Row
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

function BlogItem(props) {

    const thumbImg = props.blog.thumbnail === 'default.png' ? require('../../../assets/uploads/default.png') : props.blog.thumbnail;

    const sharelink = window.location.href + '/blog/' + props.blog._id;

    return(
        <Card className="blogCard">
            <CardBody style={{paddingBottom: 0, paddingTop: 0}}>
                <Row>
                    <Col lg={{size: "7"}}>
                        <CardImg className="blogCardImg" src={thumbImg} alt="thumbnail" />

                        <div className="blogIconContainer">
                            <div className="blogPostTime">
                                <i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
                                <small>{props.blog.datePosted}</small>
                            </div>

                            <div className="blogShare1">
                                <TwitterShareButton title={props.blog.title} url={sharelink} resetButtonStyle={false}>
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </TwitterShareButton>&nbsp;&nbsp;
                                
                                <FacebookShareButton url={sharelink} quote={props.blog.summary} resetButtonStyle={false} >
                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                </FacebookShareButton>&nbsp;&nbsp;

                                <EmailShareButton 
                                    subject={props.blog.title}
                                    body={props.blog.summary}
                                    url={sharelink}
                                    separator={'\n\n'}
                                    resetButtonStyle={false} >
                                    <i className="fa fa-share-alt" aria-hidden="true"></i>
                                </EmailShareButton>
                            </div>
                        </div>
                    </Col>
                    <Col lg={{size: "5"}} >
                        <h2 className="blogCardTitle">{props.blog.title}</h2>
                        <hr style={{marginTop: '.25rem', marginBottom: '.5rem'}}/>
                        <CardText className="blogCardText">
                            {props.blog.summary}
                        </CardText>
                        <div className="blogCardLinkContainer">
                            <Link className="blogCardLink" to={`/blog/${props.blog._id}`}>Read More</Link>
                        </div>
                        <div className="blogShare2">
                            <TwitterShareButton title={props.blog.title} url={sharelink} resetButtonStyle={false}>
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </TwitterShareButton>&nbsp;&nbsp;
                            
                            <FacebookShareButton url={sharelink} quote={props.blog.summary} resetButtonStyle={false} >
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </FacebookShareButton>&nbsp;&nbsp;

                            <EmailShareButton 
                                subject={props.blog.title}
                                body={props.blog.summary}
                                url={sharelink}
                                separator={'\n\n'}
                                resetButtonStyle={false} >
                                <i className="fa fa-share-alt" aria-hidden="true"></i>
                            </EmailShareButton>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

}

export default BlogItem;