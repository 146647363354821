import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../../actions/auth';
import { 
    Collapse, Navbar, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem,
    NavbarToggler, Nav, Container 
} from 'reactstrap';
import { getPages } from '../../../actions/pages';

class CmsNav extends Component {

    constructor(props){
        super(props);
        props.getPages();
    }

    state = {
        isOpen: false,
        hideNav: true
    }
    

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    closeNavbar = () => {
        if (this.state.isOpen && window.innerWidth < 768) {
            this.toggle();
        }
    }

    getPageLinks = (pages) => {
        return(
            <UncontrolledDropdown nav inNavbar className="cmsNavItem">
                <DropdownToggle nav caret>
                    Pages
                </DropdownToggle>
                <DropdownMenu right>
                    {pages.map(page => {
                        return (
                            <DropdownItem tag='a' href={`/admin/pages/${page.title}`} key={page._id}>
                                {page.title}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    render() {
        return(
            <Navbar color="light" light expand="md" className="cmsNavbar">
                <Container style={{padding: 0, paddingTop: 5, paddingBottom: 5}}>
                    <div className="cmsNavItem">
                        <Link to="/admin/dashboard" onClick={this.closeNavbar} style={{fontSize: 22, paddingBottom: 7}}>
                            Dashboard
                        </Link>
                    </div>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <div className="cmsNavItem">
                                <Link to="/admin/announcement" onClick={this.closeNavbar}>Announcement</Link>
                            </div>
                            <div className="cmsNavItem">
                                <Link to="/admin/audios" onClick={this.closeNavbar}>Audios</Link>
                            </div>
                            <div className="cmsNavItem">
                                <Link to="/admin/blog" onClick={this.closeNavbar}>Blog</Link>
                            </div>
                            {this.getPageLinks(this.props.pages)}
                            <div className="cmsNavItem">
                                <Link to="/admin/prayers" onClick={this.closeNavbar}>Prayers</Link>
                            </div>
                            <div className="cmsNavItem">
                                <Link to="/admin/timetable" onClick={this.closeNavbar}>Timetable</Link>
                            </div>
                        </Nav>

                        <Nav className="ml-auto" navbar>
                            <div className="cmsNavItem">
                                <Link onClick={this.props.logout} to="/admin" >Logout</Link>
                            </div>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
        
    }

}

CmsNav.propTypes = {
    logout: PropTypes.func.isRequired,
    getPages: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    pages: state.pages.pages
});

export default connect(
    mapStateToProps,
    { logout, getPages }
)(CmsNav);