import React, { Component } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Breadcrumb, Row, Col,
    Card, CardHeader, CardBody
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import PropTypes from 'prop-types';
import { editAnnouncement, getAnnouncement } from '../../../actions/announcement';
import { Editor } from '@tinymce/tinymce-react';

class EditAnnouncement extends Component {

    constructor(props){
        super(props);

        this.state = {
            body: ''
        };

        props.getAnnouncement();
    }

    onSubmit = async e => {
        e.preventDefault();
        this.props.editAnnouncement(this.props.announcement._id, this.state.body, this.props.history);
    }

    render(){
        return(
            <div>
                <SectionHeader title="Edit Announcement" buttonLabel="Back" buttonLink="/admin/announcement" />
    
                <Container>
                    <Breadcrumb>Edit Announcement</Breadcrumb>
                </Container>
    
                <Container style={{marginBottom: 100}}>
                    <Row>
                        <Col sm="12" md={{ size: 10, offset: 1 }}>
                            <Card>
                                <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                    <h2 style={{color: '#fff'}}>Edit Announcement</h2>
                                </CardHeader>
                                <CardBody>
                                    <form onSubmit={e => this.onSubmit(e)}>
                                        <Editor
                                            initialValue={this.props.announcement.body}
                                            apiKey='e94mqmq0cfu65jq97y35ta9rdcnkxhq9ulb63w02z0oftau9'
                                            init={{
                                                height: 1000,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                    `undo redo | formatselect | bold italic backcolor | 
                                                    alignleft aligncenter alignright alignjustify | 
                                                    bullist numlist outdent indent | removeformat | help`,
                                                font_formats:
                                                    `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Roboto Slab='Roboto Slab','Helvetica Neue',Helvetica,Arial,sans-serif; Scheherazade='Scheherazade',serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,
                                                content_style:
                                                    `@import url('https://fonts.googleapis.com/css2?family=Scheherazade&display=swap'); 
                                                    @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');`
                                            }}
                                            onEditorChange={(content, editor) => {
                                                this.setState({body: content});
                                            }}
                                        />
                                        <input type="submit" className="createBtn" value="Update" />
                                    </form>
    
                                    <Notify />
    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    
            </div>
        )
    }

}

EditAnnouncement.propTypes = {
    getAnnouncement: PropTypes.func.isRequired,
    editAnnouncement: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProp = state => ({
    announcement: state.announcement.announcement
});

export default connect(
    mapStateToProp,
    { getAnnouncement, editAnnouncement, setAlert }
)(EditAnnouncement);