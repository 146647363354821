import Axios from 'axios';
import {
    GET_PRAYERS,
    GET_PRAYER,
    PRAYERS_ERROR,
    ADD_PRAYER_SUCCESS,
    ADD_PRAYER_FAIL,
    EDIT_PRAYER_SUCCESS,
    EDIT_PRAYER_FAIL,
    DELETE_PRAYER_SUC,
    DELETE_PRAYER_FAIL,
    UPDATE_TABLE,
    GET_TABLE
} from './types';
import { setAlert } from './alert';


export const getPrayers = () => async dispatch =>{
    try {
        const res = await Axios.get('/api/prayers');

        dispatch({
            type: GET_PRAYERS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PRAYERS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const getPrayer = (id, history) => async dispatch => {
    try {
        const res = await Axios.get(`/api/prayers/${id}`);

        dispatch({
            type: GET_PRAYER,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PRAYERS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

        dispatch(setAlert('Could not find prayer', 'danger'));

        history.push('/admin/prayers');
    }
}

export const addPrayer = (name, time) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({name, time});

    try {
        const res = await Axios.post('/api/prayers', body, config);

        dispatch({
            type: ADD_PRAYER_SUCCESS,
            payload: res.data
        });

        dispatch(setAlert('Prayer successfully added', 'success'));
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: ADD_PRAYER_FAIL
        });
    }
}

export const editPrayer = (id, name, time, history) => async dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ name, time });

    try {
        const res = await Axios.post(`/api/prayers/${id}`, body, config);

        dispatch({
            type: EDIT_PRAYER_SUCCESS,
            payload: res.data
        });
        
        dispatch(setAlert('Prayer successfully updated', 'success'));

        history.push('/admin/prayers');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: EDIT_PRAYER_FAIL
        });
    }

}

export const deletePrayer = (id) => async dispatch => {

    try {
        const res = await Axios.delete(`/api/prayers/${id}`);

        dispatch({
            type: DELETE_PRAYER_SUC,
            payload: res.data
        });

        dispatch(getPrayers());
        
        dispatch(setAlert('Successfully deleted prayer', 'success'));

    } catch (err) {
        dispatch({
            type: DELETE_PRAYER_FAIL
        });

        dispatch(setAlert('Failed to delete prayer', 'danger'));
    }

}

export const getTimetable = () => async dispatch => {

    try{
        const res = await Axios.get('/api/timetable');

        dispatch({
            type: GET_TABLE,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PRAYERS_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const updateTable = (timetable, history) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    let data =  new FormData();
    if(timetable)
        data.append('timetable', timetable, timetable.name);

    try {
        const res = await Axios.post('/api/timetable', data, config);

        dispatch({
            type: UPDATE_TABLE,
            payload: res.data
        });

        history.push('/admin/timetable');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: PRAYERS_ERROR
        });
    }
}