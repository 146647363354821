import Axios from 'axios';
import {
    GET_ANNOUNCEMENT,
    EDIT_ANNOUNCEMENT,
    ANNOUNCEMENT_ERROR
} from './types';
import { setAlert } from './alert';

export const getAnnouncement = () => async dispatch => {
    try {
        const res = await Axios.get('/api/announcement');

        dispatch({
            type: GET_ANNOUNCEMENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: ANNOUNCEMENT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const editAnnouncement = (id, data, history) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ body: data });

    try {
        const res = await Axios.post(`/api/announcement/${id}`, body, config);

        dispatch({
            type: EDIT_ANNOUNCEMENT,
            payload: res.data
        });
        
        dispatch(setAlert('Announcement successfully updated', 'success'));

        history.push('/admin/announcement');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: ANNOUNCEMENT_ERROR
        });
    }

}