import React, { Component } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Breadcrumb, Col, Label,
    Card, CardHeader, CardBody, Input
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { getBlogs, createPost } from '../../../actions/blog';
import { Editor } from '@tinymce/tinymce-react';

class AddPost extends Component {
    constructor(props){
        super(props);

        props.getBlogs();

        this.state = {
            thumbnail: null,
            title: '',
            category: '',
            summary: '',
            content: '',
            chars: 300
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = async e => {
        e.preventDefault();
        this.props.createPost(this.state.thumbnail, this.state.title, 
                        this.state.category, this.state.summary, 
                        this.state.content, this.props.history);
    }

    handleFileUpload = e => {
        this.setState({thumbnail: e.target.files[0]});
    }

    onSumChange = (e) => {
        let inputLength = 300 - e.target.value.length;
        let input = inputLength === 0 ? this.state.summary : e.target.value; 
        this.setState({
            summary: input,
            chars: inputLength
        });
    }

    render() {
        const categories = [...new Set(this.props.blogs.map(x => x.category))];

        return(
            <div>
                <SectionHeader title="Create Post" buttonLabel="Back" buttonLink="/admin/blog" />

                <Container>
                    <Breadcrumb>Create Post</Breadcrumb>
                </Container>

                <Container>
                        <Col sm="12" md={{ size: 8, offset: 2 }} style={{marginBottom: 100}}>
                            <Card>
                                <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                    <h2 style={{color: '#fff'}}>Create Post</h2>
                                </CardHeader>
                                <CardBody>
                                    <form onSubmit={e => this.onSubmit(e)} >
                                        <Label for="title">Post Title</Label>
                                        <Input
                                            type="text" className="registerInput" 
                                            value={this.state.title} name="title" onChange={e => this.onChange(e)} /><br/>

                                            
                                        <Label for="thumbnail">Thumbnail (optional)</Label>
                                        <Input
                                            type="file" className="registerInput" 
                                            name="thumbnail" onChange={e => this.handleFileUpload(e)} /><br/>

                                        <label htmlFor="category">Category:</label><br/>
                                        <datalist id="categories">
                                            <select name="category">
                                                {categories.map(category => {
                                                    return <option key={category} value={category}>{category}</option>;
                                                })}
                                            </select>
                                            If other, please specify:
                                        </datalist> &nbsp;
                                        <input type="text" name="category" id="category" list="categories" 
                                            onChange={e => this.onChange(e)} value={this.state.category} autoComplete="off" /><br/><br/>

                                        <Label for="summary">Summary:</Label>
                                        <Input
                                            type="textarea" className="registerInput" rows="5"
                                            value={this.state.summary} name="summary" onChange={e => this.onSumChange(e)} />
                                        <p>Characters Left: {this.state.chars}</p>
                                        
                                        <Label>Article:</Label>
                                        <Editor
                                            initialValue=""
                                            apiKey='e94mqmq0cfu65jq97y35ta9rdcnkxhq9ulb63w02z0oftau9'
                                            init={{
                                                height: 1000,
                                                menubar: true,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar:
                                                    `undo redo | formatselect | bold italic backcolor | 
                                                    alignleft aligncenter alignright alignjustify | 
                                                    bullist numlist outdent indent | removeformat | help`
                                            }}
                                            onEditorChange={(content, editor) => {
                                                this.setState({content: content});
                                            }}
                                        />

                                        <input type="submit" className="createBtn" value="Post" />

                                    </form>

                                    <Notify />

                                </CardBody>
                            </Card>
                        </Col>
                </Container>

            </div>
        )
    }

}


AddPost.propTypes = {
    createPost: PropTypes.func.isRequired,
    getBlogs: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    blogs: PropTypes.array.isRequired
}

const mapStateToProp = state => ({
    blogs: state.blog.blogs
});


export default connect(
    mapStateToProp,
    { setAlert, getBlogs, createPost}
)(AddPost);