import React, { Component } from 'react';
import { Container, Row, Col, Card, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { Redirect } from 'react-router-dom';
import Notify from '../Notify';
import { checkResetToken, resetPassword } from '../../actions/reset';

class Reset extends Component {

    constructor(props){
        super(props);

        this.state = {
            password: '',
            confirmPassword: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = async e => {
        e.preventDefault();
        this.props.resetPassword(this.props.match.params.token, 
            this.state.password, this.state.confirmPassword);
    }

    UNSAFE_componentWillMount = () => {
        this.props.checkResetToken(this.props.match.params.token);
    }

    render(){
        
        if(!this.props.isResetTokenValid){
            return <Redirect to="/admin" />
        }

        return(
            <div className="loginPage">
                <Container className="loginContainer">
                    <Row style={{height: '100%'}}>
                        <Col className="my-auto text-center" lg={{size: '6', offset: '3'}}>
                            <Card className="loginCard">
    
                                <Notify />
    
                                <img src={require('../../assets/images/masjid-logo.png')} alt="masjid logo" className="img-fluid masjid-logo" style={{maxWidth: 125}} />
    
                                <h2 className="loginHeader">Reset Password</h2>
    
                                <form onSubmit={e => this.onSubmit(e)}>
                                    <Input
                                        type="password" name="password" className="form-control loginInput" 
                                        onChange={e => this.onChange(e)} value={this.state.password} placeholder="Password" />
    
                                    <Input
                                        type="password" name="confirmPassword" className="form-control loginInput" 
                                        onChange={e => this.onChange(e)} value={this.state.confirmPassword} placeholder="Confirm Password" />
    
                                    <button className="loginBtn">
                                        Reset Password
                                    </button>
                                </form>
    
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

Reset.propTypes = {
    checkResetToken: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    isResetTokenValid: PropTypes.bool.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    isResetTokenValid: state.reset.isResetTokenValid
});

export default connect(
    mapStateToProps,
    {setAlert, checkResetToken, resetPassword}
)
(Reset);

