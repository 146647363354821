import {
    GET_OVERVIEW,
    OVERVIEW_ERROR
} from './types';
import Axios from 'axios';


export const getOverview = () => async dispatch => {

    try{
        const res = await Axios.get('/api/overview');

        dispatch({
            type: GET_OVERVIEW,
            payload: res.data
        });
    } catch (err){
        dispatch({
            type: OVERVIEW_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}