import React, { Component } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Breadcrumb, Row, Col, Label,
    Card, CardHeader, CardBody, Input
} from 'reactstrap';
import Notify from '../../Notify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../../actions/alert';
import { editPrayer, getPrayer } from '../../../actions/prayers';
import { withRouter } from 'react-router-dom';

class EditPrayer extends Component {

    constructor(props){
        super(props);

        this.state = {
            name: '',
            time: '',
        };

        props.getPrayer(props.match.params.id, props.history);
    }


    onChange = e =>{
        this.setState({[e.target.name]: e.target.value})
    }

    onSubmit = async e => {
        e.preventDefault();
        this.props.editPrayer(this.props.match.params.id, this.state.name, this.state.time, this.props.history);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            name:nextProps.prayer.name,
            time:nextProps.prayer.time
        });
    }

    render(){
        return(
            <div>
                <SectionHeader title="Edit Prayer" buttonLabel="Back" buttonLink="/admin/prayers" />
    
                <Container>
                        <Breadcrumb>Prayers / {this.props.prayer.name}</Breadcrumb>
                </Container>
    
                <Container>
                    <Row>
                        <Col lg="3">
    
                        </Col>
                        <Col lg="9">
                            <Card>
                                <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                    <h2 style={{color: '#fff'}}>Edit Prayer</h2>
                                </CardHeader>
                                <CardBody>
                                    <form onSubmit={e => this.onSubmit(e)}>
                                        <Label for="name">Prayer Name</Label>
                                        <Input
                                            type="text" className="registerInput" 
                                            value={this.state.name || ""} name="name" onChange={e => this.onChange(e)} /><br/>
                                        
                                        
                                        <Label for="time">Prayer Time</Label>
                                        <Input
                                            type="text" className="registerInput" 
                                            value={this.state.time || ""} name="time" onChange={e => this.onChange(e)} />
                                        
                                        <input type="submit" className="createBtn" value="Update Prayer" />
    
                                    </form>
    
                                    <Notify />
    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    
            </div>
        )
    }

}


EditPrayer.propTypes = {
    getPrayer: PropTypes.func.isRequired,
    editPrayer: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProp = state => ({
    prayer: state.prayers.prayer
});

export default connect(
    mapStateToProp,
    { setAlert, editPrayer, getPrayer }
)(withRouter(EditPrayer));