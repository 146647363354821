import{
    GET_BLOG,
    GET_BLOGS,
    ADD_BLOG,
    UPDATE_BLOG,
    DELETE_BLOG,
    BLOG_ERROR
} from './types';
import Axios from 'axios';
import { setAlert } from './alert';

export const getBlogs = (limit) => async dispatch =>{
    try{
        const res = await Axios.get('/api/blogs', { params: { limit: limit } });
        
        dispatch({
            type: GET_BLOGS,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: BLOG_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
}

export const getBlog = (id, history) => async dispatch => {
    try{
        const res = await Axios.get(`/api/blogs/${id}`);
        
        dispatch({
            type: GET_BLOG,
            payload: res.data
        });

    } catch (err) {
        dispatch({
            type: BLOG_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });

        dispatch(setAlert('Could not find blog', 'danger'));

        history.push('/admin/blog');
    }
}

export const createPost = (thumbnail, title, category, summary, content, history) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }


    let data =  new FormData();
    if(thumbnail)
        data.append('thumbnail', thumbnail, thumbnail.name);

    data.append('title', title);
    data.append('category', category);
    data.append('summary', summary);
    data.append('content', content);
    
    try {
        const res = await Axios.post('/api/blogs', data, config);

        dispatch({
            type: ADD_BLOG,
            payload: res.data
        });

        dispatch(setAlert('Post successfully added', 'success'));

        history.push('/admin/blog');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: BLOG_ERROR
        });
    }

}

export const editPost = (id, thumbnail, title, category, summary, content, history) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }


    let data =  new FormData();
    if(thumbnail)
        data.append('thumbnail', thumbnail, thumbnail.name);

    data.append('title', title);
    data.append('category', category);
    data.append('summary', summary);
    data.append('content', content);
    
    try {
        const res = await Axios.post(`/api/blogs/${id}`, data, config);

        dispatch({
            type: UPDATE_BLOG,
            payload: res.data
        });

        dispatch(setAlert('Post successfully updated', 'success'));

        history.push('/admin/blog');
    } catch (err) {
        const errors = err.response.data.errors;

        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }

        dispatch({
            type: BLOG_ERROR
        });
    }

}

export const deletePost = (id) => async dispatch => {
    try{
        const res = await Axios.delete(`/api/blogs/${id}`);

        dispatch({
            type: DELETE_BLOG,
            payload: res.data
        });

        dispatch(getBlogs());
        dispatch(setAlert('Successfuly deleted blog post', 'success'));
    } catch (err) {
        dispatch({
            type: BLOG_ERROR
        });
        
        dispatch(setAlert('Failed to delete item', 'danger'));
    }
}