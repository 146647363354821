import { 
    GET_ADMINS, 
    ADMINS_ERROR,
    FORGOT_SUCCESS,
    FORGOT_FAIL
 } from "../actions/types";

const initialState = {
    admins: [],
    loading: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_ADMINS:
            return {
                ...state,
                admins: payload,
                loading: false
            }
        case ADMINS_ERROR:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        case FORGOT_SUCCESS:
            return{
                ...state,
                loading: false
            }
        case FORGOT_FAIL:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        default:
            return state;
    }
}