import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SectionHeader from '../cmslayout/SectionHeader';
import {
    Container, Col, Card, Breadcrumb,
    CardBody, CardHeader, Input, Label
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import Notify from '../../Notify';
import { editAudio, getAudio } from '../../../actions/audios';

class EditAudio extends Component {

    constructor(props) {
        super(props);
            
        this.state = {
            title: '',
            speaker: '',
            topic: '',
            thumbnail: null,
            content: '',
            summary: '',
            chars: 300
        }

        props.getAudio(this.props.match.params.id, this.props.history);
    }


    UNSAFE_componentWillReceiveProps(nextProps){
        let length = 300 - String(nextProps.audio.summary).length;
        this.setState({
            title:nextProps.audio.title,
            speaker:nextProps.audio.speaker,
            topic:nextProps.audio.topic,
            content:nextProps.audio.content,
            summary:nextProps.audio.summary,
            chars:length
        });
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = async e => {
        e.preventDefault();
        this.props.editAudio(this.props.match.params.id, this.state.title, 
            this.state.speaker, this.state.topic, this.state.thumbnail, 
            this.state.content, this.state.summary, this.props.history);
    }
    
    handleFileUpload = e => {
        this.setState({thumbnail: e.target.files[0]});
    }

    onSumChange = (e) => {
        let inputLength = 300 - e.target.value.length;
        let input = inputLength === 0 ? this.state.summary : e.target.value; 
        this.setState({
            summary: input,
            chars: inputLength
        });
    }

    render(){

        return(
            <div style={{marginBottom: 125}}>

                <SectionHeader title="Edit Audio" buttonLabel="Back" buttonLink="/admin/audios" />
    
                <Container>
                    <Breadcrumb>Audios / {this.props.audio.title}</Breadcrumb>
                </Container>
    
                <Container>
                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                        <Card>
                            <CardHeader style={{backgroundColor: '#00C3FF', border: 'none'}}>
                                <h2 style={{color: '#fff'}}>Edit Audio</h2>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={e => this.onSubmit(e)} >
                                    <Label for="title">Audio Title</Label>
                                    <Input
                                        type="text" className="registerInput" 
                                        value={this.state.title || ""} name="title" onChange={e => this.onChange(e)} /><br/>
                                    
                                    <Label for="speaker">Speaker</Label>
                                    <Input
                                        type="text" className="registerInput" 
                                        value={this.state.speaker || ""} name="speaker" onChange={e => this.onChange(e)} /><br/>

                                    {/* <label htmlFor="topic">Topic:</label><br/>
                                    <datalist id="topics">
                                        <select name="topic">
                                            {topics.map(topic => {
                                                return <option key={topic} value={topic}>{topic}</option>;
                                            })}
                                        </select>
                                        If other, please specify:
                                    </datalist> &nbsp;
                                    <input type="text" name="topic" id="topic" list="topics" 
                                        onChange={e => onChange(e)} value={this.state.topic} autoComplete="off" /><br/><br/> */}

                                    <Label for="thumbnail">Thumbnail (optional)</Label>
                                    <Input
                                        type="file" className="registerInput" 
                                        name="thumbnail" onChange={e => this.handleFileUpload(e)} /><br/>

                                    <Label for="content">Content (iframe)</Label>
                                    <Input
                                        type="text" className="registerInput" 
                                        value={this.state.content || ""} name="content" onChange={e => this.onChange(e)} /><br/>

                                    <Label for="summary">Summary</Label>
                                    <Input
                                        type="textarea" className="registerInput" rows="5"
                                        value={this.state.summary || ""} name="summary" onChange={e => this.onSumChange(e)} />
                                    <p>Characters Left: {this.state.chars}</p>
                                    
                                    <input type="submit" className="createBtn" value="Update" />

                                </form>

                                <Notify />

                            </CardBody>
                        </Card>
                    </Col>
                </Container>
    
            </div>
        )
    }
}

EditAudio.propTypes = {
    getAudio: PropTypes.func.isRequired,
    editAudio: PropTypes.func.isRequired,
    audio: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    audio: state.audios.audio
});

export default connect(
    mapStateToProps,
    { getAudio, editAudio }
)(withRouter(EditAudio));