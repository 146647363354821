import { 
    GET_ANNOUNCEMENT,
    EDIT_ANNOUNCEMENT,
    ANNOUNCEMENT_ERROR
} from "../actions/types";

const initialState = {
    announcement: {},
    loading: true,
    errors: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GET_ANNOUNCEMENT:
            return {
                ...state,
                announcement: payload,
                loading: false
            }
        case EDIT_ANNOUNCEMENT:
            return {
                ...state,
                loading: false
            }
        case ANNOUNCEMENT_ERROR:
            return{
                ...state,
                errors: payload,
                loading: false
            }
        default:
            return state;
    }
}