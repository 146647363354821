import React, { useEffect } from 'react';
import SectionHeader from '../cmslayout/SectionHeader';
import { 
    Container, Breadcrumb, Col, Row
} from 'reactstrap';
import Notify from '../../Notify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPage } from '../../../actions/pages';
import Twitter from '../../layout/Twitter';

const Page = ({ getPage, page, match, history }) =>{

    useEffect(() => {
        getPage(match.params.title, history);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return(
        <div>
            <SectionHeader title={`Page - ${page.title}`} buttonLabel="Edit" buttonLink={`/admin/pages/${page.title}/edit`} />

            <Container>
                <Breadcrumb>Page - {page.title}</Breadcrumb>
            </Container>

            <Container style={{marginBottom: 100}}>
                <Row>
                    <Col lg="8">
                        <Notify />
                        <div dangerouslySetInnerHTML={{ __html: page.pagebody }} />
                    </Col>
                    <Col lg="4">
                        <div style={{marginLeft: 10, marginRight: 10}}>
                            <h3> Follow Us On Twiiter </h3>
                            <Twitter/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

Page.propTypes = {
    getPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    page: state.pages.page
});

export default connect(
    mapStateToProps,
    { getPage }
)(Page);